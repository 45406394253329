// @flow

import React, {Fragment} from 'react'
import {Link, Route} from 'react-router-dom'
import loadable from '@loadable/component'
import {Switch} from "react-router";
import Footer from "../components/Footer";
import CCoreLogo from "../components/CCoreLogo";
import Header from "../components/Header";
import {useDispatch} from "react-redux";
import {changes} from "../redux/changesDuck"
import {PROJECT_TITLE} from "../config";
import IconButton from "@material-ui/core/IconButton";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import Amplify from "aws-amplify";
import Button from "@material-ui/core/Button";

const MapRoute = loadable(() => import('../routes/MapRoute'))
const DefaultRoute = loadable(() => import('../routes/DefaultRoute'))
const RequestRoute = loadable(() => import('../routes/RequestRoute'))
const ViewerRoute = loadable(() => import('../routes/ViewerRoute'))
const DashboardRoute = loadable(()=> import('../routes/DashBoardRoute'))
const QualityRoute = loadable(()=> import('../routes/QualityRoute'))

  const handleLogout = async () => {
    await Amplify.Auth.signOut()
  }

  const notImplemented = () => alert('This feature is currently under development.')

function NavigationContainer () {
    const dispatch = useDispatch()
    dispatch(changes.actions.fetchChangesLayersRequest({}))
  return (
      <Fragment>
          <Header title={PROJECT_TITLE}  >
            <Link to="/">
            <Button color='secondary'>
            <span>Dashboard</span>
            </Button>
            </Link>
            <Link to="/request">
            <Button color='secondary'>
            <span>Request</span>
            </Button>
            <Button color='secondary' onClick={notImplemented}>
            <span>Admin</span>
            </Button>
            </Link>
                <IconButton color="inherit" onClick={handleLogout}>
                     <ExitToAppIcon  />
                </IconButton>
          </Header>
          <div>
          <Switch>
            <Route path='/request' component={RequestRoute} />
            <Route path='/map' component={MapRoute} />
            <Route exact path='/viewer/:id' component={ViewerRoute} />
            <Route exact path='/qa/:id' component={QualityRoute} />
            <Route path='/' component={DashboardRoute}/>



          </Switch>
        </div>
        <Footer>
            <CCoreLogo width={'10vw'} />
        </Footer>
      </Fragment>
  )
}

export default NavigationContainer
