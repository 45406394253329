// @flow

import { createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
  themeName: 'C-CORE',
  // palette from https://material.io/resources/color/#!/?view.left=0&view.right=0&primary.color=0277BD&secondary.color=BDBDBD
  palette: {
    primary: {
      light: '#68B1E2',
      main: '#005DA6',
      dark: '#1D4387'
    },
    secondary: {
      light: '#efefef',
      main: '#bdbdbd',
      dark: '#8d8d8d'
    },
  },
  //TODO override hover as well.
  overrides: {
     MuiTableRow: {
            root: {  "&$selected": { backgroundColor: '#68B1E2', }  }
          },
  }
})

window.theme = theme

export default theme
