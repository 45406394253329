import {ofType} from 'redux-observable'
import {of, forkJoin, from, timer} from 'rxjs'
import {
    map,
    withLatestFrom,
    switchMap,
    catchError,
    tap,
    concatMap,
} from 'rxjs/operators'
import {fromFetch} from 'rxjs/fetch'
import changes from './changesDuck'
import eventLayer from  './eventLayerDuck'
import {CATALOG_ROOT, COLLECTION_URL} from "../config";
import {returnSignedApiUrl, returnSignedUrl} from "../common/authFunctions";

const fromFetchJson = (url, init = {}) => {
    return fromFetch(url, init).pipe(switchMap(response => response.json()))
}

const authRequestFromCollection = (x, mode = 'api') => {
    const url = [CATALOG_ROOT, x.href].join('')
    const signature = returnSignedUrl(url)
    return from(signature).pipe(
        switchMap(({headers}) => fromFetchJson(url, {headers}))
    )

}


export const changesEpic = (action$, state$) =>
    action$.pipe(
        ofType(eventLayer.actions.fetchChangesRequest),
        switchMap(() =>
                      getChangesfromState(state$).pipe(
                                  switchMap(x => {
            return fromFetchJson(x.url, {headers: x.headers} )
        }),
                      )
        ),
        map(result => eventLayer.actions.fetchChangesSuccess(result)),
        catchError(error => {
            console.log(error.message)
            const action = eventLayer.actions.fetchChangesFailure(error.message)
            return of(action)
        })
    )

export const changeLayerEpic = (action$, state$) =>
    action$.pipe(
        ofType(changes.actions.fetchChangesLayersRequest),
        switchMap(() => returnSignedApiUrl(COLLECTION_URL)),
        switchMap(x => {
            return fromFetchJson(x.url, {headers: x.headers})
        }),
        map(json_obj => {
            return json_obj.links.filter(i => i.rel === 'item' && i.href !== 'catalog.json')
        }),
        concatMap(
            link_items => forkJoin(
                ...link_items.map(
                    (x) => authRequestFromCollection(x, 's3')
                )
            )
        ),
        tap(x => console.log(x)), map(result => changes.actions.fetchChangesLayersSuccess(result)),
    )

function getChangesfromState(state) {
    const changeState = state.value.changes
    const activeLayer = changeState.layers[changeState.selected]
    if (!changeState) {
        return
    }
    return from(returnSignedUrl(activeLayer.assets['ndvi'].href))

}
//TODO: update polling to be more intelligent.
export const pollingEpic = (action$, state$) =>{
  const stopPolling$ = action$.pipe(
    ofType('STOP_POLLING')
  )
  return action$.pipe(
    ofType(changes.actions.fetchChangesLayersRequest),
    withLatestFrom(state$, (state) => {
      // build request params
      const params = {

      }
      return params
    }),
    switchMap(params => {
       return timer(0,  15000).pipe(
        switchMap(() => returnSignedApiUrl(COLLECTION_URL)),
        switchMap(x => {
            return fromFetchJson(x.url, {headers: x.headers})
        }),
        map(json_obj => {
            return json_obj.links.filter(i => i.rel === 'item' && i.href !== 'catalog.json')
        }),
        concatMap(
            link_items => forkJoin(
                ...link_items.map(
                    (x) => authRequestFromCollection(x, 's3')
                )
            )
        ),
        tap(x => console.log(x)), map(

              result=>{
                  if (checkforNewChanges(state$.value.changes.layers, result)){
                  return changes.actions.fetchChangesLayersSuccess(result)}
                  return {type:'EMPTY'}

                  }),
       )
    })
  )
}


function checkforNewChanges(resultLayers, stateLayers){
    //1 check for new rows
    if (resultLayers.length !== stateLayers.length) return true
    //2 check for completed results
    return resultLayers.filter(x => x.properties['cd:status'] === 'pending').length !== stateLayers.filter(x => x.properties['cd:status'] === 'pending').length;


}


// export default {changesEpic, changeLayerEpic}
