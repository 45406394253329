// Mapbox
export const mapboxAccessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN

export const PROJECT_TITLE = 'Sentinel 2 Change Detection'

export const defaultViewport = {
  latitude: 45.575846,
  longitude: -72.734720,
  zoom: 9,
}

export const testLayerUrl = "https://c-core-catalog-dev.s3.ca-central-1.amazonaws.com/s2-change-detection/index-based/21UVQ/20190710-10-21UVQ.json"
export const changeLayerUrl = "https://geoconnection-test.s3.ca-central-1.amazonaws.com/20190710-10-21UVQ-ndvi.json"
// export const CATALOG_URL = "https://c-core-catalog-dev.s3.ca-central-1.amazonaws.com/s2-change-detection/index-based/"
export const CATALOG_ROOT = "https://copsdata145054-cops.s3.amazonaws.com/private/catalog/s2-change-demo/"
export const COLLECTION_URL = "https://ow8ckt1hc3.execute-api.us-east-1.amazonaws.com/S2CDTest/collection"
export const TILE_SERVER = "https://em7zetqvxg.execute-api.eu-central-1.amazonaws.com/production/sentinel/tiles"
export const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
  };
