// @flow

import { createSlice } from '@reduxjs/toolkit'
import geoViewport from '@mapbox/geo-viewport'

import { defaultViewport } from '../config'

const initialState = {
  loading: false,
  viewport: defaultViewport,
  maxBounds: []
}

function setLoaded (state, action) {
  const { payload } = action

  state.loaded = payload
}

function setMaxBounds (state, action){
  //NOTE Max bounds in xmin ymin xmax ymax format
  const {payload} = action
    return {...state, maxBounds: payload}
}

function changeViewport (state, action) {
  const { payload } = action
  let {latitude,  longitude,  zoom} = payload
  if (state.maxBounds.length ===4){
    const [xmin, ymin, xmax, ymax] = state.maxBounds
    longitude = Math.max(Math.min(xmax, payload.longitude), xmin)
    latitude = Math.max(Math.min(ymax, payload.latitude), ymin)
  }

  return { ...state, viewport: {latitude, longitude, zoom} }
}

function fitBounds (state, action) {
  const { bbox } = action.payload
  const { viewport } = state
  const width = window.innerWidth
  const height = window.innerHeight

  const {
    center: [longitude, latitude],
    zoom
  } = geoViewport.viewport(bbox, [width, height])

  return {
    ...state,
    viewport: { ...viewport, latitude, longitude, zoom: zoom - 1 }
  }
}

const map = createSlice({
  name: 'map',
  initialState,
  reducers: {
    changeViewport,
    fitBounds,
    setLoaded,
    setMaxBounds
  }
})

export default map
