import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    selectedValue: '',
classification: {13: 'Farmland', 11: 'Construction', 14: 'Landscape Change'},
    comments: {},
    dialog: false

}

function setSelectedValue(state, action){
     const {payload} = action
     const selectedValue = payload
    return {...state, selectedValue}
}

function classifyEvent(state, action){
    const {payload} = action
    const {selectedValue, classification} = state

    if (selectedValue===''){return state}
    const newClassification = {...classification, [selectedValue]: payload}
    return  {...state, classification:newClassification}

}

function commentEvent(state, action){
    const {payload} = action
    const {selectedValue, comments} = state

    if (selectedValue===''){return state}
    const newComments = {...comments, [selectedValue]: payload}
    return  {...state, comments :newComments}

}

function setDialog(state, action){
    if (!state.selectedValue){
        return state
    }
    return {...state, dialog:!state.dialog}
}

export const quality  = createSlice(
    {name: 'quality',
    initialState,
    reducers: {
        setSelectedValue, classifyEvent, commentEvent, setDialog}
    }
)
export default quality
