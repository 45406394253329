import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/firestore'

import { firebaseConfig as config } from '../config'
console.log(config)

const firebaseConfig = {
  ...config,
  cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED,
  debug: true,
  enableLogging: true,

}

firebase.initializeApp(firebaseConfig)
firebase.firestore()

export default firebase
