// @flow

import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import {createEpicMiddleware, combineEpics} from "redux-observable";

import map from "./mapDuck"
import data from "./dataDuck"
import controls from './controlsDuck'
import changes from './changesDuck'
import quality from "./qualityDuck";
import imagery from "./imageryDuck";
import eventLayer from "./eventLayerDuck";

import {firestoreReducer} from "redux-firestore";
import dataEpic from "./dataEpic";
import {changesEpic, pollingEpic} from "./changesEpic";
import  firebase from "./firebase"

const firestore = firebase.firestore()

const epicMiddleware = createEpicMiddleware()

const rootEpic = (...args) =>
  combineEpics(dataEpic, changesEpic, pollingEpic)(...args, firestore)

const reducer = combineReducers({
  map: map.reducer,
  data:data.reducer,
  controls: controls.reducer,
  changes: changes.reducer,
  quality: quality.reducer,
  imagery: imagery.reducer,
  eventLayer: eventLayer.reducer,
  firestore: firestoreReducer,

})

const store = configureStore({
  reducer: reducer,
  middleware: [epicMiddleware],
  devTools: true
})
epicMiddleware.run(rootEpic)

window.store = store
window.firebase = firebase

export default store
