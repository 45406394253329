import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Grid from '@material-ui/core/Grid'
import SponsorLogo from './SponsorLogo'

const useStyles = makeStyles(theme => ({
  text: {
    padding: theme.spacing(2, 2, 0)
  },
  appBar: {
    top: 'auto',
    bottom: 0,
    zIndex: theme.zIndex.drawer + 1,
    paddingBottom: '10px'
  },
  grow: {
    flexGrow: 1
  },
  upperRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    direction: 'column'
  },
  copyright: {
    background: 'transparent',
    color: 'white',
    fontWeight: 'bold',
    fontSize: '10px',
    paddingTop: '4px',
    paddingBottom: '4px'
  },
  paper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  logoItem: {
    height: '56px'
  }
}))

export const Footer = () => {
  const classes = useStyles()

  return (
    <AppBar
      position='fixed'
      color='primary'
      className={classes.appBar}
      elevation={0}
    >
      <Toolbar disableGutters={true}>
        <div className={classes.grow} />
        <Grid container spacing={0}>
          <Grid container className={classes.upperRow} justify='space-between'>
            <Grid item className={classes.logoItem}>
              <SponsorLogo
                height={'45px'}
                src={'/images/LN_logoFinalPMS_white.png'}
                link={
                  'https://www.looknorth.org/'
                }
              />
            </Grid>
            <Grid item className={classes.logoItem}>
              <SponsorLogo
                height={'45px'}
                src={'/images/esa-white.svg'}
                link={
                  'https://www.looknorth.org/'
                }
              />
            </Grid>
            <Grid item className={classes.logoItem}>
              <SponsorLogo height={'56px'} src={'/images/ccore-white.png'} />
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  )
}

export default Footer
