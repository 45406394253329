//@flow
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loading: false,
}

function dataRequest (state, action) {
    const { payload } = action

    return state
}

function dataSuccess (state, action) {
    const { payload } = action

    return state
}

const data = createSlice({
  name: 'data',
  initialState,
  reducers: {
      dataRequest,
      dataSuccess
  }
})

export default data
