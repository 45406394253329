import {Auth, Signer} from "aws-amplify";

export const returnSignedApiUrl = (url) => {
    return Auth.currentCredentials().then((x) => {
        return Signer.sign(
            {method: 'GET', url: url},
            {access_key: x.accessKeyId, secret_key: x.secretAccessKey,
                session_token: x.sessionToken})
    })
}

export const returnSignedUrl = (url, region='us-east-1', service='s3') => {
    return Auth.currentCredentials().then((x) => {
        let request = Signer.sign(
            { method: 'GET', url: url },
            {access_key: x.accessKeyId, secret_key: x.secretAccessKey,
                session_token: x.sessionToken},
            {service, region})

        request.headers['x-amz-content-sha256'] = 'e3b0c44298fc1c149afbf4c8996fb92427ae41e4649b934ca495991b7852b855'
        return request
    })
}

//TODO where does this go in code?
export const buildChangeRequest = async (date, interval, wkb, job_id, email=false)=>{
  const creds = await Auth.currentAuthenticatedUser()
  // const client =  creds.signInUserSession.accessToken.payload["cognito:groups"]
  //TODO: determine how to give client to request.
  const body = {
    'email': email ?creds.attributes.email: null ,
    'wkb': wkb,
    'client': 's2-change-demo',
    'job_id': job_id,
    'interval': interval,
    'date': date,
    'requested_by': creds.username
  }
  return body
}
