// @flow
import React from 'react'
import Provider from './Provider'
import theme from './theme'
import NavigationContainer from './navigation/NavigationContainer'
import awsconfig from './aws-exports'
import Amplify from 'aws-amplify'
import { withAuthenticator, ForgotPassword, RequireNewPassword, ConfirmSignIn, SignUp } from 'aws-amplify-react'

import SignInContainer from "./containers/SignInContainer"
import store from './redux/store'
import FirebaseProvider from "./redux/firebaseProvider";

if(awsconfig){
    Amplify.configure(awsconfig);
}




function App() {
  return (
    <Provider theme={theme} store={store}>
      <FirebaseProvider store={store}>
      <NavigationContainer />
      </FirebaseProvider>
    </Provider>
  );
}


export default withAuthenticator(App, false,[
    <SignInContainer />,
    <ConfirmSignIn/>,
    <ForgotPassword/>,
    <RequireNewPassword />] );
