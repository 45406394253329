import { createSlice } from '@reduxjs/toolkit'

const TEST_LAYER = {"type": "Feature", "id": "20190710-30-18TXR",
    "bbox": [-72.56416850170231, -72.32525026427453, 45.41614675206551, 45.832370238028325],
    "geometry": {"type": "LineString", "coordinates": [[-72.54306377683827, 45.837517540556995], [-72.55954326902577, 45.42073173416707], [-71.94980205808827, 45.416875976098346]]},
    "collection": "s2-change-detection",
    "links": [{"rel": "self", "href": "https://s3.ca-central-1.amazonaws.com/c-core-catalog-dev/s2-change-detection/index-based/18TXR/20190710-30-18TXR.json"}, {"rel": "parent", "href": "./../catalog.json"}, {"rel": "collection", "href": "././../catalog.json"}, {"rel": "root", "href": "./././../catalog.json"}],
    "properties": {"datetime": null, "rp:acquisition_time": null, "rp:publish_time": "2019-10-10T14:55:30.637561", "cd:job": "test2", "cd:client": "prepare test", "cd:tile": "18TXR", "cd:target": "2019-07-10T00:00:00", "cd:interval": "30 days, 0:00:00", "cd:before_sheet": "S2B_MSIL2A_20190707T153819_N0212_R011_T18TXR_20190707T194755", "cd:after_sheet": "S2B_MSIL2A_20190707T153819_N0212_R011_T18TXR_20190707T194755", "cd:before_tile_id": "https://em7zetqvxg.execute-api.eu-central-1.amazonaws.com/production/sentinel/tiles/S2B_tile_20190707_18TXR_0/{z}/{x}/{y}.png?rgb=04,03,02&histo=100,3000-130,2700-500,4500&tile=256", "cd:after_tile_id": "https://em7zetqvxg.execute-api.eu-central-1.amazonaws.com/production/sentinel/tiles/S2B_tile_20190727_18TXR_0/{z}/{x}/{y}.png?rgb=04,03,02&histo=100,3000-130,2700-500,4500&tile=256"},
    "assets": {"after_blue": {"href": "https://roda.sentinel-hub.com/sentinel-s2-l2a/tiles/18/T/XR/2019/7/27/0/R10m/B02.jp2", "size": null}, "after_green": {"href": "https://roda.sentinel-hub.com/sentinel-s2-l2a/tiles/18/T/XR/2019/7/27/0/R10m/B03.jp2", "size": null}, "after_red": {"href": "https://roda.sentinel-hub.com/sentinel-s2-l2a/tiles/18/T/XR/2019/7/27/0/R10m/B04.jp2", "size": null}, "after_nir": {"href": "https://roda.sentinel-hub.com/sentinel-s2-l2a/tiles/18/T/XR/2019/7/27/0/R10m/B08.jp2", "size": null}, "before_blue": {"href": "https://roda.sentinel-hub.com/sentinel-s2-l2a/tiles/18/T/XR/2019/7/7/0/R10m/B02.jp2", "size": null}, "before_green": {"href": "https://roda.sentinel-hub.com/sentinel-s2-l2a/tiles/18/T/XR/2019/7/7/0/R10m/B03.jp2", "size": null}, "before_red": {"href": "https://roda.sentinel-hub.com/sentinel-s2-l2a/tiles/18/T/XR/2019/7/7/0/R10m/B04.jp2", "size": null}, "before_nir": {"href": "https://roda.sentinel-hub.com/sentinel-s2-l2a/tiles/18/T/XR/2019/7/7/0/R10m/B08.jp2", "size": null}, "ndwi": {"href": "https://s3.ca-central-1.amazonaws.com/geoconnection-test/20190710-30-18TXR-ndwi.json", "size": 622177}, "ndvi": {"href": "https://s3.ca-central-1.amazonaws.com/geoconnection-test/20190710-30-18TXR-ndvi.json", "size": 504432}}}

const initialState = {
        changeFeatures : {},
        selected: -1,
        layers: [],
        visibility: 'visible',
        fetching : false,
        fetchingList: false,
        error: false,
        selectedValue: null  }


function nextChanges(state, action){
     const selected  = Math.min(state.selected + 1, 1)
    return {...state, selected}
}

function previousChanges(state, action){
     const selected  = Math.max(state.selected - 1, 0)
    return {...state, selected}
}

function setChanges(state, action){
    const selected = action.payload
    return {...state, selected}
}

function fetchChangesRequest(state, action) {
    return {...state, fetching: true, error: false}
}

function fetchChangesSuccess(state, action) {
    const {payload} = action
        console.log(payload)


    return {...state, fetching: false, error: false, changeFeatures: payload}
}


function fetchChangesFailure (state, action) {
  console.warn({ action })
  return { ...state, fetching: false, error: true }

}

  function setFocus (state, action){
      const {payload} = action
      const selectedValue = payload
      return {...state, selectedValue}
  }

  function toggleLayer(state, action) {
    let {visibility} = state
    if (visibility === 'visible'){
        visibility = 'none'
    }else{
        visibility = 'visible'
    }
    return {...state, visibility}

}

function fetchChangesLayersRequest(state, action) {
    return {...state, fetchingList: true, error: false}
}

function fetchChangesLayersSuccess(state, action) {
      const layers = action.payload
    return {...state, layers, fetchingList: false}
}


export const changes  = createSlice(
    {name: 'changes',
    initialState,
    reducers: {
        nextChanges, setChanges,
        setFocus,
        previousChanges,
        fetchChangesRequest,
        fetchChangesSuccess,
        fetchChangesFailure, toggleLayer,
    fetchChangesLayersRequest, fetchChangesLayersSuccess}
    }
)

export default changes
