import { createSlice } from '@reduxjs/toolkit'
import { defaultViewport } from '../config'
import {EditorModes} from 'react-map-gl-draw'

function setDate(state, action){
  const datetimestring = action.payload
  return {...state, datetimestring}
}

function setInterval(state, action){
  const interval = action.payload
  return {...state, interval}
}

function setWkb(state, action){
  const wkb = action.payload
  return {...state, wkb}
}

function setMode(state, action) {
  const mode = action.payload
  return {...state, mode}
}
function setDrawControl(state, action) {
  const drawControl = action.payload
  console.log(drawControl)
  return {...state, drawControl}
}

function setEmail(state, action) {
  const email = action.payload
  return {...state, email}
}

function setFeatures(state, action){
  const {data, editType} = action.payload


  return {...state, features:data.slice(-1)}


}


const controlsDuck = createSlice({
  name: 'controls',
  initialState: {
    wkb : null,
    interval : 30,
    datetimestring: null,
    mode: EditorModes.READ_ONLY,
    drawControl: EditorModes.DRAW_POLYGON,
    email: '',
    features: []

  },
  reducers: {
    setDate,
    setInterval,
    setWkb,
    setMode,
    setDrawControl,
    setEmail,
    setFeatures
  }
})

export default controlsDuck
