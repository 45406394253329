//@flow
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loading: false,
    opacity: 1
}


  function toggleLayer(state, action) {
    let {opacity} = state

    return {...state, opacity:opacity?0:1}

}


export const imagery  = createSlice(
    {name: 'imagery',
    initialState,
    reducers: {
        toggleLayer}
    }
)
export default imagery
