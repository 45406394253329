// @flow

import React, { useState } from 'react'
import Auth from "@aws-amplify/auth"
import { Link as RouterLink } from 'react-router-dom'

import SignIn from '../components/SignIn'

function SignInContainer (props) {
  const { onStateChange } = props
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [fetching, setFetching] = useState(false)
  const [error, setError] = useState(undefined)

  async function handleLogin (event) {
    if (email === '') {
      setError('Enter your email')
    } else if (password === '') {
      setError('Enter your password')
    } else {
      setError(undefined)
      setFetching(true)
      try {
        const user = await Auth.signIn(email, password);
        setFetching(false)
        onStateChange('signedIn', user)
      } catch (error) {
        console.warn(error)
        setFetching(false)
        setError(error.message)
      }
    }
  }

  return (
    <SignIn
      handleLogin={handleLogin}
      email={email}
      password={password}
      error={error}
      fetching={fetching}
      setEmail={setEmail}
      setPassword={setPassword}
      RouterLink={RouterLink}
    />
  )
}

export default SignInContainer
