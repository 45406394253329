//Slice of state pertaining to the change event layer

import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    selectedValue: '',
    changes: {},
    filtered: false,
    error: false,
    fetching: true,
    outline: []
}


function fetchChangesSuccess(state, action) {
    const {payload} = action
        console.log(payload)


    return {...state, fetching: false, error: false, changes: payload}
}

function fetchChangesFailure (state, action) {
  console.warn({ action })
  return { ...state, fetching: false, error: true }

}

function fetchChangesRequest(state, action) {
    return {...state, fetching: true, error: false}
}




const eventLayer  = createSlice(
    {name: 'events',
    initialState,
    reducers: {fetchChangesRequest, fetchChangesSuccess, fetchChangesFailure}
    }
)

export default eventLayer
